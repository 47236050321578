<template>
  <div>
    <img
      src="assets/images/logo.png"
      alt=""
      class="css-13ep12v"
      style="width: 40px; height: 40px"
    />
  </div>
</template>

<script>
export default {
  name: 'logo',
}
</script>

<style></style>
