import { h, resolveComponent } from 'vue'
import { createRouter, createWebHashHistory } from 'vue-router'
import allowedRoutes from './allowedRoutes'
import DefaultLayout from '@/layouts/DefaultLayout'
import store from '@/store'

const routes = [
  {
    path: '/home',
    name: 'Home',
    component: DefaultLayout,
    redirect: '/dashboard',
    children: [
      {
        path: '/dashboard',
        name: 'Dashboard',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
          import(/* webpackChunkName: "dashboard" */ '@/views/Dashboard.vue'),
      },
      {
        path: '/settings',
        name: 'Settings',
        component: () => import('@/views/pages/Settings.vue'),
      },
      {
        path: '/flows',
        name: 'Flow',
        component: () => import('@/views/boards/BoardView.vue'),
      },
      {
        path: '/flows/edit',
        name: 'FlowForm',
        component: () => import('@/views/flows/FlowForm.vue'),
      },
      {
        path: '/boards',
        name: 'Boards',
        component: () => import('@/views/flows/FlowList.vue'),
      },
      {
        path: '/samples',
        name: 'samples',
        component: () => import('@/views/samples/List.vue'),
      },
      {
        path: '/samples/register',
        name: 'samplesRegister',
        component: () => import('@/views/samples/Register.vue'),
      },
      {
        path: '/iventory',
        name: 'iventory',
        component: () => import('@/views/iventory/List.vue'),
      },
      {
        path: '/tasks',
        name: 'tasks',
        component: () => import('@/views/tasks/List.vue'),
      },
      {
        path: '/quality',
        name: 'quality',
        component: () => import('@/views/quality/List.vue'),
      },
      {
        path: '/associated-data',
        name: 'associated-data',
        component: () => import('@/views/associated-data/List.vue'),
      },
      {
        path: '/consumables',
        name: 'consumables',
        component: () => import('@/views/consumables/List.vue'),
      },
      {
        path: '/reports',
        name: 'reports',
        component: () => import('@/views/reports/List.vue'),
      },
      {
        path: '/integrations',
        name: 'integrations',
        component: () => import('@/views/integrations/List.vue'),
      },
      {
        path: '/security',
        name: 'security',
        component: () => import('@/views/security/List.vue'),
      },
      {
        path: '/help',
        name: 'help',
        component: () => import('@/views/help/List.vue'),
      },
      {
        path: '/barCodeScanner',
        name: 'barCodeScanner',
        component: () => import('@/views/barCodeScanner.vue'),
      },
    ],
  },
  {
    path: '/:pathMatch(.*)*',
    redirect: '/pages/404',
    name: 'Pages',
    component: {
      render() {
        return h(resolveComponent('router-view'))
      },
    },
    children: [
      {
        path: '404',
        name: 'Page404',
        component: () => import('@/views/pages/Page404'),
      },
      {
        path: '500',
        name: 'Page500',
        component: () => import('@/views/pages/Page500'),
      },
      {
        path: '/',
        name: 'Login',
        component: () => import('@/views/pages/Login'),
      },
      {
        path: 'register',
        name: 'Register',
        component: () => import('@/views/pages/Register'),
      },
    ],
  },
]

const router = createRouter({
  history: createWebHashHistory(process.env.BASE_URL),
  routes,
  scrollBehavior() {
    // always scroll to top
    return { top: 0 }
  },
})

router.beforeEach((to, from, next) => {
  const logged = store?.state?.loggedInfo?.logged == true
  const loginPages = [
    'Login',
    'Register',
    'Page404',
    'Page500',
    'barCodeScanner',
  ]

  if (!loginPages.includes(to?.name) && !logged) {
    next('/')
    return
  }
  next()
})

export default router
