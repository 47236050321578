<template>
  <CDropdown variant="nav-item" @click="logout">
    <CDropdownToggle placement="bottom-start" class="py-0" :caret="false">
      <CAvatar
        :src="$store.state.userphoto"
        size="md"
        style="background: #efefef"
      />
    </CDropdownToggle>
    <!-- 
    <CDropdownMenu class="pt-0">
      
      <CDropdownHeader component="h6" class="bg-dark fw-semibold py-2">
        {{ $t('preferences') }}
      </CDropdownHeader>
      <CDropdownItem>
        <CIcon icon="cil-user" /> {{ $t('profile') }}
      </CDropdownItem>
      <CDropdownItem>
        <CIcon icon="cil-settings" @click="$utils.redirect('settings')" />
        {{ $t('settings') }}
      </CDropdownItem>
      <CDropdownHeader component="h6" class="bg-dark fw-semibold py-2">
        {{ $t('Language') }}
      </CDropdownHeader>
      <CDropdownItem @click="setIdiom('pt')"> Português </CDropdownItem>
      <CDropdownItem @click="setIdiom('en')"> English </CDropdownItem>
      <CDropdownDivider />
      <CDropdownItem @click="logout" class="cursor-pointer">
        <CIcon icon="cil-lock-locked" /> {{ $t('logout') }}
      </CDropdownItem>
    </CDropdownMenu>-->
  </CDropdown> 
</template>

<script>
import avatar from '@/assets/images/avatars/8.jpg'
export default {
  name: 'AppHeaderDropdownAccnt',
  mounted() {
    this.$utils.setUserData(localStorage.getItem('user-token'), this)
    this.$i18n.locale = 'pt'
  },
  data() {
    return {
      avatar: avatar,
      itemsCount: 42,
    }
  },
  methods: {
    setIdiom(lang = 'en') {
      this.$i18n.locale = lang
    },
    logout() {
      // localStorage.clear()
      
      this.$store.commit({
        type: 'setLogged',
        value: null,
      })
      // setTimeout(() => {
      //   window.location.reload()
      // }, '300')
    },
  },
}
</script>
