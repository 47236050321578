<template>
  <div class="d-flex justify-content-center">
    <div id="barCodeElement"></div>
  </div>
</template>

<script>
import Quagga from 'quagga' // ES6

export default {
  emits: ['emitResult'],
  data() {
    return {
      result: null,
      code: null,
    }
  },
  mounted() {
    Quagga.init(
      {
        inputStream: {
          name: 'Live',
          type: 'LiveStream',
          target: document.querySelector('#barCodeElement'), // Or '#yourElement' (optional)
        },
        numOfWorkers: 4,
        frequency: 100,
        debug: true,
        decoder: {
          readers: [
            'code_128_reader',
            'ean_reader',
            'ean_8_reader',
            'code_39_reader',
            'code_39_vin_reader',
            'codabar_reader',
            'upc_reader',
            'upc_e_reader',
            'i2of5_reader',
            '2of5_reader',
            'code_93_reader',
          ],
        },
      },
      (err) => {
        if (err) {
          return
        }
        let result = null
        console.log('Initialization finished. Ready to start')
        Quagga.start()
        Quagga.onProcessed((res) => {
          try {
            if (!res) return
            result = JSON.stringify(res)
          } catch (error) {
            return
          }
        })

        setInterval(() => {
          if (!result) return
          if (!result.includes('codeResult')) return
          this.result = result
          this.code = JSON.parse(result).codeResult.code
          console.log('resultado setado!')
          this.emitResult()
        }, 2000)
      },
    )
  },
  methods: {
    emitResult() {
      this.$emit('emitResult', {
        code: this.code,
        result: this.result,
      })
    },
  },
}
</script>

<style></style>
