import mockData from './misc/mockData'
// import tablemock from '@/views/df_andamento.json'

export default {
  data() {
    return {
      // Dados globais para todos os componentes
      logconsumo: {
        rows: mockData.logconsumo(),
      },
      consumables: {
        rows: mockData.consumables(),
      },
      table: {
        isLoading: false,
        columns: [
          // {
          //   label: "ID",
          //   field: "id",
          //   width: "3%",
          //   sortable: true,
          //   isKey: true,
          // },
          // {
          //   label: "Name",
          //   field: "name",
          //   width: "10%",
          //   sortable: true,
          // },
          // {
          //   label: "Details",
          //   field: "details",
          //   width: "15%",
          //   sortable: true,
          // },
        ],
        rows: mockData.randomTable(),
        totalRecordCount: 900,
        sortable: {
          order: 'id',
          sort: 'asc',
        },
      },
    }
  },
  methods: {
    // Métodos globais para todos os componentes
    capitalize(str) {
      return str.charAt(0).toUpperCase() + str.slice(1)
    },
  },
  computed: {
    // Propriedades computadas globais para todos os componentes
  },
}
