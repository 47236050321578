<template>
  <CHeader position="sticky" class="mb-4">
    <CContainer fluid>
      <CHeaderToggler class="ps-1" @click="$store.commit('toggleSidebar')">
        <CIcon icon="cil-menu" size="lg" />
      </CHeaderToggler>
      <CHeaderBrand class="me-4 mx-auto d-lg-none" to="/">
        <CRow>
          <CCol>
            <logo />
          </CCol>
          <CCol class="mt-2">BioRepoSitio</CCol>
        </CRow>
      </CHeaderBrand>
      <!-- <CHeaderNav class="d-none d-md-flex me-auto">
        <CNavItem>
          <CNavLink href="" id="dashboard-link">Dashboard</CNavLink>
        </CNavItem>
        <CNavItem>
          <CNavLink @click="$utils.redirect('settings')" id="settings-link">Configurações</CNavLink>
        </CNavItem>
      </CHeaderNav> -->
      <CHeaderNav class="ms-auto me-4">
        <CButtonGroup aria-label="Theme switch">
          <CFormCheck
            id="btn-light-theme"
            type="radio"
            :button="{ color: 'primary' }"
            name="theme-switch"
            autocomplete="off"
            :checked="$store.state.theme === 'default'"
            @change="
              (event) =>
                $store.commit({
                  type: 'toggleTheme',
                  value: 'default',
                })
            "
          >
            <template #label><CIcon icon="cil-sun" /></template>
          </CFormCheck>
          <CFormCheck
            id="btn-dark-theme"
            type="radio"
            :button="{ color: 'primary' }"
            name="theme-switch"
            autocomplete="off"
            :checked="$store.state.theme === 'dark'"
            @change="
              (event) =>
                $store.commit({
                  type: 'toggleTheme',
                  value: 'dark',
                })
            "
          >
            <template #label><CIcon icon="cil-moon" /></template>
          </CFormCheck>
        </CButtonGroup>
      </CHeaderNav>
      <CHeaderNav class="me-3">
        <QRScanner />
      </CHeaderNav>
      <CHeaderNav class="me-3">
        <CIcon
          icon="cil-search"
          size="lg"
          id="displaySearchIcon"
          class="text-secondary cursor-pointer"
          @click="displaySearch"
        />
      </CHeaderNav>
      <CHeaderNav class="me-3">
        <CIcon
          icon="cil-bell"
          size="lg"
          class="text-secondary cursor-pointer"
        />
      </CHeaderNav>
      <CHeaderNav class="me-3">
        &nbsp;
        <span
          :class="`text-${
            $i18n.locale == 'en' ? 'primary' : 'secondary'
          } cursor-pointer`"
          @click="setIdiom('en')"
        >
          EN
        </span>
        &nbsp; &nbsp;
        <span
          :class="`text-${
            $i18n.locale == 'pt' ? 'primary' : 'secondary'
          } cursor-pointer`"
          @click="setIdiom('pt')"
        >
          PT
        </span>
        &nbsp; &nbsp;
        <span
          :class="`text-${
            $i18n.locale == 'es' ? 'primary' : 'secondary'
          } cursor-pointer`"
          @click="setIdiom('es')"
        >
          ES
        </span>
      </CHeaderNav>
      <CHeaderNav>
        <AppHeaderDropdownAccnt />
      </CHeaderNav>
    </CContainer>
    <CHeaderDivider />
    <CContainer fluid>
      <AppBreadcrumb />
    </CContainer>
  </CHeader>
  <CModal
    alignment="center"
    :backdrop="true"
    size="lg"
    @close="
      () => {
        this.displayGlobalSearch = false
      }
    "
    @hide="
      () => {
        this.displayGlobalSearch = false
      }
    "
    :visible="displayGlobalSearch"
    ><CModalHeader>
      <CModalTitle>Pesquisar</CModalTitle>
    </CModalHeader>
    <CModalBody><globalSearch v-if="displayGlobalSearch" /></CModalBody>
  </CModal>
</template>

<script>
import AppBreadcrumb from './AppBreadcrumb'
import AppHeaderDropdownAccnt from './AppHeaderDropdownAccnt'
import logo from '@/assets/logo'
import QRScanner from '@/components/dashboard/QRScanner'
import globalSearch from '@/components/globalSearch/globalSearch'

export default {
  name: 'AppHeader',
  components: {
    AppBreadcrumb,
    AppHeaderDropdownAccnt,
    globalSearch,
    logo,
    QRScanner,
  },
  data() {
    return {
      displayGlobalSearch: false,
    }
  },
  methods: {
    setIdiom(lang = 'en') {
      this.$i18n.locale = lang
    },
    displaySearch() {
      console.log('global search')
      this.displayGlobalSearch = true
    },
  },
}
</script>
