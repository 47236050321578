<template>
  <svg
    id="Capa_1"
    style="enable-background: new 0 0 60 60"
    version="1.1"
    viewBox="0 0 60 60"
    xml:space="preserve"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
  >
    <g>
      <path
        d="M6,48.5v-17c0-0.553-0.448-1-1-1s-1,0.447-1,1v17c0,0.553,0.448,1,1,1S6,49.053,6,48.5z"
      />
      <path
        d="M56,48.5v-17c0-0.553-0.448-1-1-1s-1,0.447-1,1v17c0,0.553,0.448,1,1,1S56,49.053,56,48.5z"
      />
      <path
        d="M9,30.5c-0.552,0-1,0.447-1,1v9c0,0.553,0.448,1,1,1s1-0.447,1-1v-9C10,30.947,9.552,30.5,9,30.5z"
      />
      <path
        d="M12,30.5c-0.552,0-1,0.447-1,1v9c0,0.553,0.448,1,1,1s1-0.447,1-1v-9C13,30.947,12.552,30.5,12,30.5z"
      />
      <path
        d="M18,40.5v-9c0-0.553-0.448-1-1-1s-1,0.447-1,1v9c0,0.553,0.448,1,1,1S18,41.053,18,40.5z"
      />
      <path
        d="M21,40.5v-9c0-0.553-0.448-1-1-1s-1,0.447-1,1v9c0,0.553,0.448,1,1,1S21,41.053,21,40.5z"
      />
      <path
        d="M24,40.5v-9c0-0.553-0.448-1-1-1s-1,0.447-1,1v9c0,0.553,0.448,1,1,1S24,41.053,24,40.5z"
      />
      <path
        d="M30,40.5v-9c0-0.553-0.448-1-1-1s-1,0.447-1,1v9c0,0.553,0.448,1,1,1S30,41.053,30,40.5z"
      />
      <path
        d="M35,31.5c0-0.553-0.448-1-1-1s-1,0.447-1,1v9c0,0.553,0.448,1,1,1s1-0.447,1-1V31.5z"
      />
      <path
        d="M38,31.5c0-0.553-0.448-1-1-1s-1,0.447-1,1v9c0,0.553,0.448,1,1,1s1-0.447,1-1V31.5z"
      />
      <path
        d="M42,30.5c-0.552,0-1,0.447-1,1v9c0,0.553,0.448,1,1,1s1-0.447,1-1v-9C43,30.947,42.552,30.5,42,30.5z"
      />
      <path
        d="M45,30.5c-0.552,0-1,0.447-1,1v9c0,0.553,0.448,1,1,1s1-0.447,1-1v-9C46,30.947,45.552,30.5,45,30.5z"
      />
      <path
        d="M51,41.5c0.552,0,1-0.447,1-1v-9c0-0.553-0.448-1-1-1s-1,0.447-1,1v9C50,41.053,50.448,41.5,51,41.5z"
      />
      <path
        d="M4,11.5v14c0,0.553,0.448,1,1,1s1-0.447,1-1v-14c0-0.553-0.448-1-1-1S4,10.947,4,11.5z"
      />
      <path
        d="M56,25.5v-14c0-0.553-0.448-1-1-1s-1,0.447-1,1v14c0,0.553,0.448,1,1,1S56,26.053,56,25.5z"
      />
      <path
        d="M8,25.5c0,0.553,0.448,1,1,1s1-0.447,1-1v-14c0-0.553-0.448-1-1-1s-1,0.447-1,1V25.5z"
      />
      <path
        d="M12,26.5c0.552,0,1-0.447,1-1v-14c0-0.553-0.448-1-1-1s-1,0.447-1,1v14C11,26.053,11.448,26.5,12,26.5z"
      />
      <path
        d="M17,26.5c0.552,0,1-0.447,1-1v-14c0-0.553-0.448-1-1-1s-1,0.447-1,1v14C16,26.053,16.448,26.5,17,26.5z"
      />
      <path
        d="M20,26.5c0.552,0,1-0.447,1-1v-14c0-0.553-0.448-1-1-1s-1,0.447-1,1v14C19,26.053,19.448,26.5,20,26.5z"
      />
      <path
        d="M23,26.5c0.552,0,1-0.447,1-1v-14c0-0.553-0.448-1-1-1s-1,0.447-1,1v14C22,26.053,22.448,26.5,23,26.5z"
      />
      <path
        d="M29,26.5c0.552,0,1-0.447,1-1v-14c0-0.553-0.448-1-1-1s-1,0.447-1,1v14C28,26.053,28.448,26.5,29,26.5z"
      />
      <path
        d="M34,26.5c0.552,0,1-0.447,1-1v-14c0-0.553-0.448-1-1-1s-1,0.447-1,1v14C33,26.053,33.448,26.5,34,26.5z"
      />
      <path
        d="M37,26.5c0.552,0,1-0.447,1-1v-14c0-0.553-0.448-1-1-1s-1,0.447-1,1v14C36,26.053,36.448,26.5,37,26.5z"
      />
      <path
        d="M42,26.5c0.552,0,1-0.447,1-1v-14c0-0.553-0.448-1-1-1s-1,0.447-1,1v14C41,26.053,41.448,26.5,42,26.5z"
      />
      <path
        d="M45,26.5c0.552,0,1-0.447,1-1v-14c0-0.553-0.448-1-1-1s-1,0.447-1,1v14C44,26.053,44.448,26.5,45,26.5z"
      />
      <path
        d="M51,26.5c0.552,0,1-0.447,1-1v-14c0-0.553-0.448-1-1-1s-1,0.447-1,1v14C50,26.053,50.448,26.5,51,26.5z"
      />
      <path
        d="M9,44.5c-0.552,0-1,0.447-1,1v1c0,0.553,0.448,1,1,1s1-0.447,1-1v-1C10,44.947,9.552,44.5,9,44.5z"
      />
      <path
        d="M12,44.5c-0.552,0-1,0.447-1,1v1c0,0.553,0.448,1,1,1s1-0.447,1-1v-1C13,44.947,12.552,44.5,12,44.5z"
      />
      <path
        d="M15,44.5c-0.552,0-1,0.447-1,1v1c0,0.553,0.448,1,1,1s1-0.447,1-1v-1C16,44.947,15.552,44.5,15,44.5z"
      />
      <path
        d="M18,44.5c-0.552,0-1,0.447-1,1v1c0,0.553,0.448,1,1,1s1-0.447,1-1v-1C19,44.947,18.552,44.5,18,44.5z"
      />
      <path
        d="M21,44.5c-0.552,0-1,0.447-1,1v1c0,0.553,0.448,1,1,1s1-0.447,1-1v-1C22,44.947,21.552,44.5,21,44.5z"
      />
      <path
        d="M24,44.5c-0.552,0-1,0.447-1,1v1c0,0.553,0.448,1,1,1s1-0.447,1-1v-1C25,44.947,24.552,44.5,24,44.5z"
      />
      <path
        d="M27,44.5c-0.552,0-1,0.447-1,1v1c0,0.553,0.448,1,1,1s1-0.447,1-1v-1C28,44.947,27.552,44.5,27,44.5z"
      />
      <path
        d="M30,44.5c-0.552,0-1,0.447-1,1v1c0,0.553,0.448,1,1,1s1-0.447,1-1v-1C31,44.947,30.552,44.5,30,44.5z"
      />
      <path
        d="M33,44.5c-0.552,0-1,0.447-1,1v1c0,0.553,0.448,1,1,1s1-0.447,1-1v-1C34,44.947,33.552,44.5,33,44.5z"
      />
      <path
        d="M36,44.5c-0.552,0-1,0.447-1,1v1c0,0.553,0.448,1,1,1s1-0.447,1-1v-1C37,44.947,36.552,44.5,36,44.5z"
      />
      <path
        d="M39,44.5c-0.552,0-1,0.447-1,1v1c0,0.553,0.448,1,1,1s1-0.447,1-1v-1C40,44.947,39.552,44.5,39,44.5z"
      />
      <path
        d="M42,44.5c-0.552,0-1,0.447-1,1v1c0,0.553,0.448,1,1,1s1-0.447,1-1v-1C43,44.947,42.552,44.5,42,44.5z"
      />
      <path
        d="M45,44.5c-0.552,0-1,0.447-1,1v1c0,0.553,0.448,1,1,1s1-0.447,1-1v-1C46,44.947,45.552,44.5,45,44.5z"
      />
      <path
        d="M48,44.5c-0.552,0-1,0.447-1,1v1c0,0.553,0.448,1,1,1s1-0.447,1-1v-1C49,44.947,48.552,44.5,48,44.5z"
      />
      <path
        d="M52,45.5c0-0.553-0.448-1-1-1s-1,0.447-1,1v1c0,0.553,0.448,1,1,1s1-0.447,1-1V45.5z"
      />
      <path
        d="M8,53.5H2v-5c0-0.553-0.448-1-1-1s-1,0.447-1,1v7h8c0.552,0,1-0.447,1-1S8.552,53.5,8,53.5z"
      />
      <path
        d="M59,47.5c-0.552,0-1,0.447-1,1v5h-5c-0.552,0-1,0.447-1,1s0.448,1,1,1h7v-7C60,47.947,59.552,47.5,59,47.5z"
      />
      <path
        d="M52,4.5c-0.552,0-1,0.447-1,1s0.448,1,1,1h6v5c0,0.553,0.448,1,1,1s1-0.447,1-1v-7H52z"
      />
      <path
        d="M1,12.5c0.552,0,1-0.447,1-1v-5h5c0.552,0,1-0.447,1-1s-0.448-1-1-1H0v7C0,12.053,0.448,12.5,1,12.5z"
      />
      <path
        d="M59,27.5H1c-0.552,0-1,0.447-1,1s0.448,1,1,1h58c0.552,0,1-0.447,1-1S59.552,27.5,59,27.5z"
      />
    </g>
    <g />
    <g />
    <g />
    <g />
    <g />
    <g />
    <g />
    <g />
    <g />
    <g />
    <g />
    <g />
    <g />
    <g />
    <g />
  </svg>
</template>

<script>
export default {}
</script>

<style>
#Capa_1 {
  height: 30px !important;
}
</style>
