import { createI18n } from 'vue-i18n'
import SystemUtils from '@/utils/SystemUtils'
import pt from '@/langs/pt'
import en from '@/langs/en'
import es from '@/langs/es'

const i18n = createI18n({
    locale: SystemUtils.getLanguage(),
    fallbackLocale: 'pt',
    messages: {
        'pt': pt,
        'en': en,
        'pt-BR': pt,
        'es': es,
    },
})

export default i18n