<template>
  <div>
    <!-- <CButton variant="outline" color="transparent"> -->
    <CIcon icon="cil-qr-code"  @click="() => { qr_scanner_modal = true }" size="lg" class="mt-1 text-secondary cursor-pointer"/>
    <!-- </CButton> -->
    <CModal backdrop="static" :visible="qr_scanner_modal" @close="() => { qr_scanner_modal = false }">
        <CModalHeader>
            <CModalTitle>{{$t('scanner')}} QR Code</CModalTitle>
        </CModalHeader>
        <CModalBody>
            <p class="error">{{ error }}</p>

            <p class="decode-result"><b>{{ result }}</b></p>

            <qrcode-stream @decode="onDecode" @init="onInit" />
        </CModalBody>
        <CModalFooter>
            <CButton variant="outline" color="success" @click="openResult" v-if="is_valid" size="lg">
                {{$t('go')}}
            </CButton>
        </CModalFooter>
    </CModal>
  </div>
</template>

<script>
    import { QrcodeStream, QrcodeDropZone, QrcodeCapture } from 'vue3-qrcode-reader'
    import popupMsg from '@/utils/popupMsg'
    export default {
        components: {
            QrcodeStream,
            QrcodeDropZone,
            QrcodeCapture
        },
        data(){
            return {
                qr_scanner_modal: false,
                is_valid: false,
                error: '',
                result: '',
            }
        },
        methods:{
            openResult(){
                window.open(
                    `${this.result}`, 
                    '_blank'
                )
            },
            onDecode (result) {
                this.is_valid = true
                this.result = result
                this.error = ''
            },
            async onInit (promise) {
                try {
                    await promise
                } catch (error) {
                    if (error.name === 'NotAllowedError') {
                        this.error = "ERROR: you need to grant camera access permission"
                    } else if (error.name === 'NotFoundError') {
                        this.error = "ERROR: no camera on this device"
                    } else if (error.name === 'NotSupportedError') {
                        this.error = "ERROR: secure context required (HTTPS, localhost)"
                    } else if (error.name === 'NotReadableError') {
                        this.error = "ERROR: is the camera already in use?"
                    } else if (error.name === 'OverconstrainedError') {
                        this.error = "ERROR: installed cameras are not suitable"
                    } else if (error.name === 'StreamApiNotSupportedError') {
                        this.error = "ERROR: Stream API is not supported in this browser"
                    } else if (error.name === 'InsecureContextError') {
                        this.error = 'ERROR: Camera access is only permitted in secure context. Use HTTPS or localhost rather than HTTP.';
                    } else {
                        this.error = `ERROR: Camera error (${error.name})`;
                    }
                }
            }
        }
    }
</script>

<style scoped>
    .error {
        font-weight: bold;
        color: red;
    }
</style>