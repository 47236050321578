<template>
  <label>
    <small class="text-muted">
      {{ name }}
    </small>
  </label>
</template>

<script>
export default {
  props: ['name'],
}
</script>

<style></style>
