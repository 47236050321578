import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import i18n from '@/plugins/I18n'
import SystemUtils from '@/utils/SystemUtils'
import CoreuiVue from '@coreui/vue-pro'
import CIcon from '@coreui/icons-vue'
import { iconsSet as icons } from '@/assets/icons'
import DocsCallout from '@/components/DocsCallout'
import barcode from '@/assets/barcode.vue'
import DocsExample from '@/components/DocsExample'
import Label from '@/components/generic/Label'
import 'toastify-js/src/toastify.css'
import CoreuiVueCharts from '@coreui/vue-chartjs'
import '@/assets/global.scss'
import '@/assets/multiselect.scss'
import TableLite from 'vue3-table-lite'
import global from './global.js' // Arquivo do seu mixin

const app = createApp(App)
app.use(i18n)
app.use(SystemUtils)
app.use(store)
app.use(router)
app.use(CoreuiVue)
app.use(CoreuiVueCharts)
// app.use(TableLite)
app.component('TableLite', TableLite)
app.provide('icons', icons)
app.provide('$utils', SystemUtils)
app.component('CIcon', CIcon)
app.component('DocsCallout', DocsCallout)
app.component('barcode-icon', barcode)
app.component('DocsExample', DocsExample)
app.component('Label', Label)
app.config.globalProperties.$utils = SystemUtils
app.mixin(global)

app.mount('#app')
