<template>
  <div class="row">
    <div class="col-md">
      <div class="">
        <div class="row">
          <div class="col-md-6">
            <Label :name="$t('Buscar')" />
            <div class="input-group mb-3">
              <input
                type="text"
                class="form-control"
                v-model="identifierFilter"
              />
              <span class="input-group-text cursor-pointer">
                <CIcon
                  icon="cil-search"
                  size="lg"
                  class="text-secondary cursor-pointer"
                />
              </span>
            </div>
          </div>
          <div class="col-md-6 d-flex justify-content-end">
            <div>
              <br />
              <span
                class="input-group-text cursor-pointer"
                @click="bar_code_reader_visible = true"
              >
                <barcode-icon />
              </span>
            </div>
          </div>
        </div>
        <div class="row mt-2">
          <div class="col-md-12">
            <div class="row mt-2">
              <div class="col-md">
                <table class="table table-responsive table-hover table-striped">
                  <thead>
                    <tr>
                      <th
                        v-for="(key, idx) in Object.keys(samples[0])"
                        :key="idx"
                        class="text-center"
                      >
                        {{ key }}
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(row, i) in samplesFiltered" :key="i">
                      <td
                        v-for="(key, idx) in Object.keys(samples[0])"
                        :key="idx"
                        class="text-center"
                      >
                        <span v-if="booleanColumns.includes(key)">
                          <span v-if="row[key] == true">
                            <CIcon
                              style="font-scale: 2px"
                              icon="cil-check-circle"
                              class="text-success"
                            />
                          </span>
                          <span v-else>
                            <CIcon
                              style="font-scale: 2px"
                              icon="cil-bell-exclamation"
                              class="text-danger"
                            />
                          </span>
                        </span>
                        <span
                          v-else-if="key == 'Data Prevista para Fim do Estoque'"
                          >{{ new Date(row[key]).toLocaleString() }}</span
                        >
                        <span
                          v-else
                          :class="
                            key == 'Identificação'
                              ? 'text-primary cursor-pointer underline'
                              : ''
                          "
                          >{{ row[key] }}</span
                        >
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <CModal
    alignment="center"
    :backdrop="true"
    size="lg"
    :visible="bar_code_reader_visible"
    @close="bar_code_reader_visible = false"
  >
    <CModalHeader>
      <CModalTitle>Leitor de código de barras</CModalTitle>
    </CModalHeader>
    <CModalBody>
      <div class="row mt-3">
        <div class="col-md-12">
          <barCodeScanner @emitResult="setBarCode" />
        </div>
      </div>
      <!-- <div class="row" v-if="barCode.code"> -->
      <div class="row">
        <div class="col-md">
          <Label :name="'Code:'" />
          <input
            disabled
            class="form-control"
            type="text"
            :value="barCode.code"
          />
        </div>
        <div class="col-md-4 d-flex justify-content-end" v-if="barCode.result">
          <div>
            <br />
            <button
              v-if="barCode.code"
              type="button"
              class="btn btn-primary h45px mr-1"
              @click="
                () => {
                  bar_code_reader_visible = false
                }
              "
            >
              Confirmar
            </button>
            <button
              type="button"
              class="btn btn-primary h45px"
              @click="
                () => {
                  qrCodeDetailsShow = true
                }
              "
            >
              Ver detalhes
            </button>
          </div>
        </div>
        <div class="col-md-12 mt-3" v-if="qrCodeDetailsShow">
          <textarea
            class="form-control"
            disabled
            rows="15"
            :value="barCode.result"
          ></textarea>
        </div>
      </div>
      <div class="row mt-3" v-if="!barCode.code">
        <div class="col-md">
          <small class="text-danger"
            >Nenhum código foi identificado. Por favor, tente escanear um código
            de barras</small
          >
          <br />

          <small class="text-danger"
            >Por favor, habilite o acesso ao leitor do código de barras à
            câmera.</small
          >
        </div>
      </div>
    </CModalBody>
    <!-- <CModalFooter> </CModalFooter> -->
  </CModal>
</template>

<script>
import CIcon from '@coreui/icons-vue'
import barCodeScanner from '@/components/BarCodeScanner'

export default {
  components: {
    CIcon,
    barCodeScanner,
  },
  methods: {
    setBarCode(data) {
      this.$utils.popupMsg('Código de barras escaneado com sucesso!')
      this.barCode = data
      this.identifierFilter = data?.code || ''
      this.bar_code_reader_visible = false
    },
  },
  computed: {
    samplesFiltered() {
      return this.samples.filter((s) => {
        return (
          `${s.Identificação.split('SAMPLE')[1]}`.includes(
            `${this.identifierFilter}`,
          ) ||
          `${this.identifierFilter}`.includes(
            `${s.Identificação.split('SAMPLE')[1]}`,
          ) ||
          `${s.Código}`.includes(`${this.identifierFilter}`) ||
          `${this.identifierFilter}`.includes(`${s.Código}`)
        )
      })
    },
  },
  data() {
    return {
      barCode: { code: null, result: null },
      bar_code_reader_visible: false,
      qrCodeDetailsShow: false,
      identifierFilter: '',
      booleanColumns: ['Alertas'],
      samples: [
        {
          Identificação: this.$utils.getRandomName(),
          Código: this.$utils.getRandomName('', 100000000000, 10000000000),
          Comentário:
            'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
        },
        {
          Identificação: this.$utils.getRandomName(),
          Código: this.$utils.getRandomName('', 100000000000, 10000000000),
          Comentário:
            'Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.',
        },
        {
          Identificação: this.$utils.getRandomName(),
          Código: this.$utils.getRandomName('', 100000000000, 10000000000),
          Comentário:
            'Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.',
        },
        {
          Identificação: this.$utils.getRandomName(),
          Código: this.$utils.getRandomName('', 100000000000, 10000000000),
          Comentário:
            'Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
        },
        {
          Identificação: this.$utils.getRandomName(),
          Código: this.$utils.getRandomName('', 100000000000, 10000000000),
          Comentário:
            'Nam libero tempore, cum soluta nobis est eligendi optio cumque nihil impedit quo minus id quod maxime placeat facere possimus.',
        },
        {
          Identificação: this.$utils.getRandomName(),
          Código: this.$utils.getRandomName('', 100000000000, 10000000000),
          Comentário:
            'Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.',
        },
        {
          Identificação: this.$utils.getRandomName(),
          Código: this.$utils.getRandomName('', 100000000000, 10000000000),
          Comentário:
            'Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
        },
        {
          Identificação: this.$utils.getRandomName(),
          Código: this.$utils.getRandomName('', 100000000000, 10000000000),
          Comentário:
            'Nam libero tempore, cum soluta nobis est eligendi optio cumque nihil impedit quo minus id quod maxime placeat facere possimus.',
        },
        {
          Identificação: this.$utils.getRandomName(),
          Código: this.$utils.getRandomName('', 100000000000, 10000000000),
          Comentário:
            'Nam libero tempore, cum soluta nobis est eligendi optio cumque nihil impedit quo minus id quod maxime placeat facere possimus.',
        },
        {
          Identificação: this.$utils.getRandomName(),
          Código: this.$utils.getRandomName('', 100000000000, 10000000000),
          Comentário:
            'Nam libero tempore, cum soluta nobis est eligendi optio cumque nihil impedit quo minus id quod maxime placeat facere possimus.',
        },
        {
          Identificação: this.$utils.getRandomName(),
          Código: this.$utils.getRandomName('', 100000000000, 10000000000),
          Comentário:
            'Nam libero tempore, cum soluta nobis est eligendi optio cumque nihil impedit quo minus id quod maxime placeat facere possimus.',
        },
        {
          Identificação: this.$utils.getRandomName(),
          Código: this.$utils.getRandomName('', 100000000000, 10000000000),
          Comentário:
            'Nam libero tempore, cum soluta nobis est eligendi optio cumque nihil impedit quo minus id quod maxime placeat facere possimus.',
        },
        {
          Identificação: this.$utils.getRandomName(),
          Código: this.$utils.getRandomName('', 100000000000, 10000000000),
          Comentário:
            'Nam libero tempore, cum soluta nobis est eligendi optio cumque nihil impedit quo minus id quod maxime placeat facere possimus.',
        },
        {
          Identificação: this.$utils.getRandomName(),
          Código: this.$utils.getRandomName('', 100000000000, 10000000000),
          Comentário:
            'Nam libero tempore, cum soluta nobis est eligendi optio cumque nihil impedit quo minus id quod maxime placeat facere possimus.',
        },
        {
          Identificação: this.$utils.getRandomName(),
          Código: this.$utils.getRandomName('', 100000000000, 10000000000),
          Comentário:
            'Nam libero tempore, cum soluta nobis est eligendi optio cumque nihil impedit quo minus id quod maxime placeat facere possimus.',
        },
        {
          Identificação: this.$utils.getRandomName(),
          Código: this.$utils.getRandomName('', 100000000000, 10000000000),
          Comentário:
            'Nam libero tempore, cum soluta nobis est eligendi optio cumque nihil impedit quo minus id quod maxime placeat facere possimus.',
        },
        {
          Identificação: this.$utils.getRandomName(),
          Código: this.$utils.getRandomName('', 100000000000, 10000000000),
          Comentário:
            'Nam libero tempore, cum soluta nobis est eligendi optio cumque nihil impedit quo minus id quod maxime placeat facere possimus.',
        },
        {
          Identificação: this.$utils.getRandomName(),
          Código: this.$utils.getRandomName('', 100000000000, 10000000000),
          Comentário:
            'Nam libero tempore, cum soluta nobis est eligendi optio cumque nihil impedit quo minus id quod maxime placeat facere possimus.',
        },
        {
          Identificação: this.$utils.getRandomName(),
          Código: this.$utils.getRandomName('', 100000000000, 10000000000),
          Comentário:
            'Nam libero tempore, cum soluta nobis est eligendi optio cumque nihil impedit quo minus id quod maxime placeat facere possimus.',
        },
        {
          Identificação: this.$utils.getRandomName(),
          Código: this.$utils.getRandomName('', 100000000000, 10000000000),
          Comentário:
            'Nam libero tempore, cum soluta nobis est eligendi optio cumque nihil impedit quo minus id quod maxime placeat facere possimus.',
        },
        {
          Identificação: this.$utils.getRandomName(),
          Código: this.$utils.getRandomName('', 100000000000, 10000000000),
          Comentário:
            'Nam libero tempore, cum soluta nobis est eligendi optio cumque nihil impedit quo minus id quod maxime placeat facere possimus.',
        },
        {
          Identificação: this.$utils.getRandomName(),
          Código: this.$utils.getRandomName('', 100000000000, 10000000000),
          Comentário:
            'Nam libero tempore, cum soluta nobis est eligendi optio cumque nihil impedit quo minus id quod maxime placeat facere possimus.',
        },
        {
          Identificação: this.$utils.getRandomName(),
          Código: this.$utils.getRandomName('', 100000000000, 10000000000),
          Comentário:
            'Nam libero tempore, cum soluta nobis est eligendi optio cumque nihil impedit quo minus id quod maxime placeat facere possimus.',
        },
        {
          Identificação: this.$utils.getRandomName(),
          Código: this.$utils.getRandomName('', 100000000000, 10000000000),
          Comentário:
            'Nam libero tempore, cum soluta nobis est eligendi optio cumque nihil impedit quo minus id quod maxime placeat facere possimus.',
        },
        {
          Identificação: this.$utils.getRandomName(),
          Código: this.$utils.getRandomName('', 100000000000, 10000000000),
          Comentário:
            'Nam libero tempore, cum soluta nobis est eligendi optio cumque nihil impedit quo minus id quod maxime placeat facere possimus.',
        },
        {
          Identificação: this.$utils.getRandomName(),
          Código: this.$utils.getRandomName('', 100000000000, 10000000000),
          Comentário:
            'Nam libero tempore, cum soluta nobis est eligendi optio cumque nihil impedit quo minus id quod maxime placeat facere possimus.',
        },
        {
          Identificação: this.$utils.getRandomName(),
          Código: this.$utils.getRandomName('', 100000000000, 10000000000),
          Comentário:
            'Nam libero tempore, cum soluta nobis est eligendi optio cumque nihil impedit quo minus id quod maxime placeat facere possimus.',
        },
        {
          Identificação: this.$utils.getRandomName(),
          Código: this.$utils.getRandomName('', 100000000000, 10000000000),
          Comentário:
            'Nam libero tempore, cum soluta nobis est eligendi optio cumque nihil impedit quo minus id quod maxime placeat facere possimus.',
        },
        {
          Identificação: this.$utils.getRandomName(),
          Código: this.$utils.getRandomName('', 100000000000, 10000000000),
          Comentário:
            'Nam libero tempore, cum soluta nobis est eligendi optio cumque nihil impedit quo minus id quod maxime placeat facere possimus.',
        },
        {
          Identificação: this.$utils.getRandomName(),
          Código: this.$utils.getRandomName('', 100000000000, 10000000000),
          Comentário:
            'Nam libero tempore, cum soluta nobis est eligendi optio cumque nihil impedit quo minus id quod maxime placeat facere possimus.',
        },
        {
          Identificação: this.$utils.getRandomName(),
          Código: this.$utils.getRandomName('', 100000000000, 10000000000),
          Comentário:
            'Nam libero tempore, cum soluta nobis est eligendi optio cumque nihil impedit quo minus id quod maxime placeat facere possimus.',
        },
        {
          Identificação: this.$utils.getRandomName(),
          Código: this.$utils.getRandomName('', 100000000000, 10000000000),
          Comentário:
            'Nam libero tempore, cum soluta nobis est eligendi optio cumque nihil impedit quo minus id quod maxime placeat facere possimus.',
        },
        {
          Identificação: this.$utils.getRandomName(),
          Código: this.$utils.getRandomName('', 100000000000, 10000000000),
          Comentário:
            'Nam libero tempore, cum soluta nobis est eligendi optio cumque nihil impedit quo minus id quod maxime placeat facere possimus.',
        },
        {
          Identificação: this.$utils.getRandomName(),
          Código: this.$utils.getRandomName('', 100000000000, 10000000000),
          Comentário:
            'Nam libero tempore, cum soluta nobis est eligendi optio cumque nihil impedit quo minus id quod maxime placeat facere possimus.',
        },
        {
          Identificação: this.$utils.getRandomName(),
          Código: this.$utils.getRandomName('', 100000000000, 10000000000),
          Comentário:
            'Nam libero tempore, cum soluta nobis est eligendi optio cumque nihil impedit quo minus id quod maxime placeat facere possimus.',
        },
        {
          Identificação: this.$utils.getRandomName(),
          Código: this.$utils.getRandomName('', 100000000000, 10000000000),
          Comentário:
            'Nam libero tempore, cum soluta nobis est eligendi optio cumque nihil impedit quo minus id quod maxime placeat facere possimus.',
        },
        {
          Identificação: this.$utils.getRandomName(),
          Código: this.$utils.getRandomName('', 100000000000, 10000000000),
          Comentário:
            'Nam libero tempore, cum soluta nobis est eligendi optio cumque nihil impedit quo minus id quod maxime placeat facere possimus.',
        },
        {
          Identificação: this.$utils.getRandomName(),
          Código: this.$utils.getRandomName('', 100000000000, 10000000000),
          Comentário:
            'Nam libero tempore, cum soluta nobis est eligendi optio cumque nihil impedit quo minus id quod maxime placeat facere possimus.',
        },
        {
          Identificação: this.$utils.getRandomName(),
          Código: this.$utils.getRandomName('', 100000000000, 10000000000),
          Comentário:
            'Nam libero tempore, cum soluta nobis est eligendi optio cumque nihil impedit quo minus id quod maxime placeat facere possimus.',
        },
        {
          Identificação: this.$utils.getRandomName(),
          Código: this.$utils.getRandomName('', 100000000000, 10000000000),
          Comentário:
            'Nam libero tempore, cum soluta nobis est eligendi optio cumque nihil impedit quo minus id quod maxime placeat facere possimus.',
        },
        {
          Identificação: this.$utils.getRandomName(),
          Código: this.$utils.getRandomName('', 100000000000, 10000000000),
          Comentário:
            'Nam libero tempore, cum soluta nobis est eligendi optio cumque nihil impedit quo minus id quod maxime placeat facere possimus.',
        },
        {
          Identificação: this.$utils.getRandomName(),
          Código: this.$utils.getRandomName('', 100000000000, 10000000000),
          Comentário:
            'Nam libero tempore, cum soluta nobis est eligendi optio cumque nihil impedit quo minus id quod maxime placeat facere possimus.',
        },
        {
          Identificação: this.$utils.getRandomName(),
          Código: this.$utils.getRandomName('', 100000000000, 10000000000),
          Comentário:
            'Nam libero tempore, cum soluta nobis est eligendi optio cumque nihil impedit quo minus id quod maxime placeat facere possimus.',
        },
        {
          Identificação: this.$utils.getRandomName(),
          Código: this.$utils.getRandomName('', 100000000000, 10000000000),
          Comentário:
            'Nam libero tempore, cum soluta nobis est eligendi optio cumque nihil impedit quo minus id quod maxime placeat facere possimus.',
        },
        {
          Identificação: this.$utils.getRandomName(),
          Código: this.$utils.getRandomName('', 100000000000, 10000000000),
          Comentário:
            'Nam libero tempore, cum soluta nobis est eligendi optio cumque nihil impedit quo minus id quod maxime placeat facere possimus.',
        },
        {
          Identificação: this.$utils.getRandomName(),
          Código: this.$utils.getRandomName('', 100000000000, 10000000000),
          Comentário:
            'Nam libero tempore, cum soluta nobis est eligendi optio cumque nihil impedit quo minus id quod maxime placeat facere possimus.',
        },
        {
          Identificação: this.$utils.getRandomName(),
          Código: this.$utils.getRandomName('', 100000000000, 10000000000),
          Comentário:
            'Nam libero tempore, cum soluta nobis est eligendi optio cumque nihil impedit quo minus id quod maxime placeat facere possimus.',
        },
        {
          Identificação: this.$utils.getRandomName(),
          Código: this.$utils.getRandomName('', 100000000000, 10000000000),
          Comentário:
            'Nam libero tempore, cum soluta nobis est eligendi optio cumque nihil impedit quo minus id quod maxime placeat facere possimus.',
        },
        {
          Identificação: this.$utils.getRandomName(),
          Código: this.$utils.getRandomName('', 100000000000, 10000000000),
          Comentário:
            'Nam libero tempore, cum soluta nobis est eligendi optio cumque nihil impedit quo minus id quod maxime placeat facere possimus.',
        },
        {
          Identificação: this.$utils.getRandomName(),
          Código: this.$utils.getRandomName('', 100000000000, 10000000000),
          Comentário:
            'Nam libero tempore, cum soluta nobis est eligendi optio cumque nihil impedit quo minus id quod maxime placeat facere possimus.',
        },
        {
          Identificação: this.$utils.getRandomName(),
          Código: this.$utils.getRandomName('', 100000000000, 10000000000),
          Comentário:
            'Nam libero tempore, cum soluta nobis est eligendi optio cumque nihil impedit quo minus id quod maxime placeat facere possimus.',
        },
        {
          Identificação: this.$utils.getRandomName(),
          Código: this.$utils.getRandomName('', 100000000000, 10000000000),
          Comentário:
            'Nam libero tempore, cum soluta nobis est eligendi optio cumque nihil impedit quo minus id quod maxime placeat facere possimus.',
        },
        {
          Identificação: this.$utils.getRandomName(),
          Código: this.$utils.getRandomName('', 100000000000, 10000000000),
          Comentário:
            'Nam libero tempore, cum soluta nobis est eligendi optio cumque nihil impedit quo minus id quod maxime placeat facere possimus.',
        },
        {
          Identificação: this.$utils.getRandomName(),
          Código: this.$utils.getRandomName('', 100000000000, 10000000000),
          Comentário:
            'Nam libero tempore, cum soluta nobis est eligendi optio cumque nihil impedit quo minus id quod maxime placeat facere possimus.',
        },
        {
          Identificação: this.$utils.getRandomName(),
          Código: this.$utils.getRandomName('', 100000000000, 10000000000),
          Comentário:
            'Nam libero tempore, cum soluta nobis est eligendi optio cumque nihil impedit quo minus id quod maxime placeat facere possimus.',
        },
        {
          Identificação: this.$utils.getRandomName(),
          Código: this.$utils.getRandomName('', 100000000000, 10000000000),
          Comentário:
            'Nam libero tempore, cum soluta nobis est eligendi optio cumque nihil impedit quo minus id quod maxime placeat facere possimus.',
        },
        {
          Identificação: this.$utils.getRandomName(),
          Código: this.$utils.getRandomName('', 100000000000, 10000000000),
          Comentário:
            'Nam libero tempore, cum soluta nobis est eligendi optio cumque nihil impedit quo minus id quod maxime placeat facere possimus.',
        },
        {
          Identificação: this.$utils.getRandomName(),
          Código: this.$utils.getRandomName('', 100000000000, 10000000000),
          Comentário:
            'Nam libero tempore, cum soluta nobis est eligendi optio cumque nihil impedit quo minus id quod maxime placeat facere possimus.',
        },
        {
          Identificação: this.$utils.getRandomName(),
          Código: this.$utils.getRandomName('', 100000000000, 10000000000),
          Comentário:
            'Nam libero tempore, cum soluta nobis est eligendi optio cumque nihil impedit quo minus id quod maxime placeat facere possimus.',
        },
      ],
      cols: [
        {
          label: 'Identifier',
          field: 'identifier',
          width: '10%',
          sortable: true,
        },
        {
          label: 'Name',
          field: 'name',
          width: '60%',
          sortable: true,
        },
        {
          label: 'Details',
          field: 'detail',
          width: '30%',
          sortable: true,
        },
      ],
    }
  },
}
</script>

<style></style>
