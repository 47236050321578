export default [
  {
    component: 'CNavItem',
    name: 'Dashboard',
    icon: 'cil-chart-pie',
    to: '/dashboard'
  },// - cil-chart-pie
  {
    component: 'CNavItem',
    name: 'Amostras',
    icon: 'cil-apps-settings',// ou cil-circle ou cil-apps-settings',
    to: '/samples'
  },// - cil-grain ou cil-circle ou cil-apps-settings
  {
    component: 'CNavItem',
    name: 'Inventário',
    icon: 'cil-center-focus',
    to: '/iventory'
  },// - cil-center-focus
  {
    component: 'CNavItem',
    name: 'Tarefas',
    icon: 'cil-list',
    to: '/tasks'
  },// - cil-center-focus
  {
    component: 'CNavItem',
    name: 'Fluxos',
    icon: 'cil-view-column',// ou cil-stream ou cil-list',
    to: '/flows'
  },// - cil-view-column ou cil-stream ou cil-list
  {
    component: 'CNavItem',
    name: 'Qualidade',
    icon: 'cil-check-circle',
    to: '/quality'
  },// - cil-check-circle
  {
    component: 'CNavItem',
    name: 'Dados Associados',
    icon: 'cil-library-add',// ou cil-paperclip',
    to: '/associated-data'
  },// - cil-library-add ou cil-paperclip,// - cil-check-circle
  {
    component: 'CNavItem',
    name: 'Consumíveis',
    icon: 'cil-beaker',// ou cil-paperclip',
    to: '/consumables'
  },// - cil-library-add ou cil-paperclip
  {
    component: 'CNavItem',
    name: 'Relatórios',
    icon: 'cil-graph',// ou cil-spreadsheet',
    to: '/reports'
  },// - cil-graph ou cil-spreadsheet
  {
    component: 'CNavItem',
    name: 'Integrações',
    icon: 'cil-external-link',
    to: '/integrations'
  },// - cil-external-link
  {
    component: 'CNavItem',
    name: 'Segurança',
    icon: 'cil-fingerprint',// ou cil-lock-locked',
    to: '/security'
  },// - cil-fingerprint ou cil-lock-locked
  
  {
    component: 'CNavItem',
    name: 'Ajuda e Suporte',
    icon: 'cil-chat-bubble',
    to: '/help'
  },// - cil-chat-bubble
  {
    component: 'CNavItem',
    name: 'Configurações',
    icon: 'cil-cog',
    to: '/settings',
  },// - cil-cog
  // {
  //   component: 'CNavItem',
  //   name: 'dashboard',
  //   to: '/dashboard',
  //   icon: 'cil-chart-pie',
  //   badge: {
  //     // color: 'info',
  //     // text: 'NEW',
  //   },
  // },
  // {
  //   component: 'CNavItem',
  //   name: 'Flows',
  //   to: '/flows',
  //   icon: 'cil-list',
  //   badge: {
  //     // color: 'info',
  //     // text: 'NEW',
  //   },
  // },
  // {
  //   component: 'CNavItem',
  //   name: 'Boards flows',
  //   to: '/boards',
  //   icon: 'cil-view-module',
  //   badge: {
  //     // color: 'info',
  //     // text: 'NEW',
  //   },
  // },
  // {
  //   tag: 'hr',
  // },
  // {
  //   component: 'CNavItem',
  //   name: 'settings',
  //   to: '/settings',
  //   icon: 'cil-cog',
  //   badge: {
  //     // color: 'info',
  //     // text: 'NEW',
  //   },
  // },
]
